<template>
  <div class="home" id="quotaReviewList">
    <div style="text-align: left" >
      <el-form :inline="true" :model="queryForm" class="demo-form-inline">
        <el-form-item label="月度汇总表编号：">
          <el-input v-model="queryForm.summaryNo" placeholder="请输入查询" :clearable="true"></el-input>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select v-model="queryForm.status" placeholder="请选择">
            <el-option label="全部状态" value="-1"></el-option>
            <el-option v-for="(itme,i) in itemList" :key="i" :label="itme.name" :value="itme.datavalue"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="loadTable" icon="el-icon-search">查询</el-button>

          <el-button type="primary" @click="onReset" icon="el-icon-refresh-right">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div style="text-align: left;margin-bottom: 20px;">
      <el-button type="primary" icon="el-icon-plus" @click="openUploadFrom('上传发薪模版:')">上传发薪模版</el-button>
      <el-button type="primary" icon="el-icon-plus" @click="openUploadFrom('上传申报数据表:')">上传申报数据表</el-button>
      <el-button type="primary" icon="el-icon-plus" @click="downloadSalary">下载发薪模板</el-button>
    </div>
    <div>
        <el-table  :header-cell-style="tableHeader" :cell-style="tableCell" :data="tableData" fit border style="width: 100%">
        <el-table-column type="index" width="50" label="" />
        <el-table-column prop="summaryNo" :show-overflow-tooltip="true"  label="汇总表编号" />
        
        <el-table-column prop="errorCount" :show-overflow-tooltip="true" label="异常记录数" >
          <template slot-scope="scope">
            <el-link :type="scope.row.errorCount == 0 ? 'info' : 'danger'" @click="openError(scope.row)" :disabled="scope.row.errorCount == 0" size="medium" style="font-weight: bold;">{{scope.row.errorCount}}</el-link>
          </template>
        </el-table-column>

        <el-table-column prop="examinepeople" :show-overflow-tooltip="true" label="审核人" />
        <el-table-column prop="examineTime" :show-overflow-tooltip="true" label="审核时间" />
        <el-table-column prop="updateTime" :show-overflow-tooltip="true" label="上传时间" />
        <el-table-column prop="submitUser" :show-overflow-tooltip="true" label="提交人员" />
        <el-table-column prop="status" :show-overflow-tooltip="true"  label="发薪状态">
          <template slot-scope="scope">
            <div v-for="(item, index) in itemList" v-bind:key="index" >
              <div v-if="item.datavalue == scope.row.status">
                {{  item.name }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="errdsp" label="失败描述" :show-overflow-tooltip="true" align="center" width="250px"/>
        <el-table-column label="操作" width="250px" >
          <template slot-scope="scope">
            <el-button @click="noDetails(scope.row)" type="text" size="small">详情</el-button> 
            <el-button type="text" size="small" @click="submitAudit(scope.row,0)" v-if="scope.row.status == 0" style="color: #09BF88">提交审核</el-button> 
            <el-button type="text" size="small" @click="repealAudit(scope.row,1)" v-else-if="scope.row.status == 1" style="color: #FFAC1D">撤销审核</el-button>
            <el-button type="text" size="small" @click="submitAudits(scope.row,0)" v-if="scope.row.status == 4 || scope.row.status == 3"  style="color: #09BF88">重新提交</el-button> 
            <el-button type="text" size="small" @click="openUploadFrom('上传发薪模版:', scope.row.id)" v-if="scope.row.status != 5"  style="color: #F78969">重新上传</el-button> 
            <el-button type="text" size="small" @click="deleteSalarys(scope.row)" v-if="scope.row.status != 6 && scope.row.status != 7">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
     <el-pagination
        id="page"
        background
        @size-change="sizeChange"
        @current-change="currentChange"
        @prev-click="currentChange"
        @next-click="currentChange"
        :page-sizes="[5, 10, 20]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <div>

      <el-dialog width="80%" :title="'发薪时间:'+parseTable.headDate" :visible.sync="innerVisible" >
          <div>
            <el-table
              :header-cell-style="tableHeader" :cell-style="tableCell"
              :data="parseTableData"
              height="450"
              border
              style="width: 100%">
              <el-table-column type="index" label="编号"  width="50" />
              <el-table-column prop="date" label="日期" /> 
              <el-table-column prop="id" label="身份证" />
              <el-table-column prop="name" label="姓名" />
              <el-table-column prop="money" label="本次薪酬" />
              <el-table-column prop="comment" label="备注" />
            </el-table>
            <div class="parseXlsxBottom">
              <el-pagination
                background
                @size-change="parseXlsxPagesizeChange"
                @current-change="parseXlsxPageChange"
                @prev-click="parseXlsxPageChange"
                @next-click="parseXlsxPageChange"
                :page-sizes="[10, 20]"
                :page-size="parsePage.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="parseTableTotal">
              </el-pagination>
              <el-button type="primary" @click="innerVisible = false">关闭预览</el-button>
            </div>
          </div>
        </el-dialog>
      <!-- 外层 -->
      <el-dialog :title="title" :visible.sync="dialogFormVisible" width="50%" style="text-align: initial;" @closed="afteraddOrUpdateDialogClose">

        <!-- 内层 -->
        

        <el-form :model="updateForm" :label-position="'left'" ref="updateForm" :rules="updateFormRules" label-width="150" class="updateForm" >
          <el-form-item :label="title"  prop="isFile" >

            <el-upload
              class="upload-demo"
              ref="upload"
              :action="upload"
              :on-preview="handlePreview"
              :on-error="handleError"
              :on-remove="handleRemove"
              :on-success="handlesuccess"
              :file-list="fileList"
              :limit=1
              accept=".xls,.xlsx"
              :data="uploadJson"
              :auto-upload="false">
              <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
              <!-- <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button> -->
              <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件。</div>
            </el-upload>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="submitUploadFrom">确 定</el-button>

        </div>
      </el-dialog>

      <el-dialog
        :visible.sync="auditMsg.dialogVisible"
        width="30%">
        <!-- <el-form class="wageadjustments" :model="changeStateParams" :inline="true" :label-position="'right'"  :rules="rules" ref="updateForm">
          <el-form-item label="个税承担:" prop="radio">
            <el-radio v-model="changeStateParams.bear" label="0">员工承担</el-radio>
            <el-radio v-model="changeStateParams.bear" label="1">公司承担</el-radio>
          </el-form-item>
        </el-form> -->
        <span :class="auditMsg.msgClass">{{auditMsg.msg}}</span>
        
        <span slot="footer" class="dialog-footer">
          <el-button @click="auditMsg.dialogVisible = false">取 消</el-button>
          <el-button :class="auditMsg.buttonClass" v-loading.lock="fullscreenLoading" type="primary" @click="changeState">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog
        :visible.sync="auditMsg.dialogVisibles"
        width="30%">
        <el-form class="wageadjustments" :model="changeStateParams" :inline="true" :label-position="'right'"  :rules="rules" ref="updateForm">
          <el-form-item label="个税承担:" prop="radio">
            <el-radio v-model="changeStateParams.bear" label="0">员工承担</el-radio>
            <el-radio v-model="changeStateParams.bear" label="1">公司承担</el-radio>
          </el-form-item>
        </el-form>
        <span :class="auditMsg.msgClass">{{auditMsg.msg}}</span>
        
        <span slot="footer" class="dialog-footer">
          <el-button @click="auditMsg.dialogVisibles = false">取 消</el-button>
          <el-button :class="auditMsg.buttonClass" v-loading.lock="fullscreenLoading" type="primary" @click="changeState">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog
        :visible.sync="auditMsgs.dialogVisible"
        width="30%"
        >
        <span :class="auditMsgs.msgClass">{{auditMsgs.msg}}</span>
        
        <span slot="footer" class="dialog-footer">
          <el-button @click="auditMsgs.dialogVisible = false">取 消</el-button>
          <el-button :class="auditMsgs.buttonClass" type="primary" @click="deleteSalary">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>

export default {
    data() {
      return {
        // tableLoading: true,
        //upload: process.env.VUE_APP_BASE_URL+'/salarySummarizing/importSalary',
        upload:process.env.VUE_APP_BASE_URL+'/salarySummarizing/importPayrollDeclaration',
        // 0为汇总
        loading: true,
        type: 0,
        title:'',//上传汇总表还是申报数据表
        queryForm: {
          summaryNo: '',
          groupIds: null,
          groupId: '',
          status: '',
          pageNo: 1,
          pageSize: 10
        },
        total:0,
        // 这个站点对象集合
        fullscreenLoading: false,
        groupList: [],
        options: [],
        // 状态字典列表
        summaryStateList:[],
        // 以表示排列的数组
        summaryArray: null,
        // 表格对象
        tableData: [],
        // input: '',
        updateForm: {
          groupIds: [],
          groupId: this.queryFormGetGroupIds,
          groupName: '',
          sessionId: '',
          type: 0,
          creditLimitApprovalId: '',
          isFile: null,
        },
        rules: {
           radio: [
            { required: true, message: '请选择额度审核', trigger: 'change' }
          ],
        },
        updateFormRules: {
          groupIds:[
             { required: true, message: '请选择部门！', trigger: 'change' }
          ],
          isFile:[
             { required: true, message: '请选择文件！', trigger: 'change' }
          ]
        },
        uploadJson:{
          json: {},
          userId: '',
          sessionId:'',
          flag:0,
          auth:3,
          summaryID:'',
          type: process.env.VUE_APP_UPLOAD_TYPE,
          queue: process.env.VUE_APP_UPLOAD_SALARY_SUMMARY_QUENCE_NAME
        },
        formInline: {
          user: '',
          region: ''
        },
        dialogFormVisible: false,
        innerVisible: false,
        fileList: [],
        auditMsg: {
          dialogVisible: false,
          dialogVisibles: false,
          msg: '确认撤销审核该汇总表吗？',
          msgClass: 'submitMsg',
          buttonClass: 'submitButton',
          // 提交为0，撤销为1
          status: 0
        },
        // 这个是解析表格
        parseTable:{
          headDate: '',
          tableData: [{date: '',id:'',name:'',money:'',comment:''}],

        },
        parsePage:{
          pageNo: 1,
          pageSize: 10,
        },
        queryParams: {
        pageNo: 1,
        pageSize: 10,
        code: "",
        name: "",
      },
      changeStateParams:{
        id: '',
        status: null,
        bear: '0',
        sessionId: '',
        errorNum:''
      },
      itemList: [],
      auditMsg: {
          dialogVisible: false,
          dialogVisibles: false,
          msgClass: 'submitMsg',
          buttonClass: 'submitButton',
        },

      auditMsgs: {
        dialogVisible: false,
        msgClass: 'submitMsg',
        buttonClass: 'submitButton',
      },
      diction: {
					code: "summaryStatus",
				},
      }
    },
    computed: {
      parseTableData(){
        return this.parseTable.tableData.slice((this.parsePage.pageNo-1)*this.parsePage.pageSize,this.parsePage.pageSize*this.parsePage.pageNo);
      },
      parseTableTotal(){
        return this.parseTable.tableData.length;
      },
    },
    watch: {
      queryFormGetGroupIds(newVal, oldVal){
        this.queryForm.groupId = newVal;
      }
    },
    // 实例创建完成后调用

    mounted: function(){
				this.findDictionariesItemList(this.diction);
		},
    async created(){
		let flag = this;
      // 获取分組 信息
      await this.api.platformApi.getGroupTree({}).then((res) => {
        res = res.data
        if(res.code == 200){
          flag.options = res.data;
		//   flag.loading = false;
        }
      })
	  this.loadTable();
    },



    methods: {

      openError(row){
        let summid = row.id;
        let summaryNo = row.summaryNo;
        let createTime = row.createTime;
        this.$router.push({path:'/authFailedList',query: {summid,summaryNo,createTime}})
      },


       // 分页查看薪资信息列表
		async loadTable(){
			this.loading = true;
			let flag = this;
			//getStaffList
      // console.log(this.queryForm);
			await this.api.platformApi.listImportSalary(this.queryForm).then((res) => {
				res = res.data
				console.log("----计薪列表-----",res);
				// flag.loading = false;
				if(res.code == 200){
					flag.tableData = res.data.records;
					flag.total = res.data.total
				}else{
            this.tableData =  null;
            this.total = 0;
            this.$message.error(res.msg);
            flag.loading = false;
        }
			})
		},
    async deleteSalary(){
      console.log("-------canshu",this.changeStateParams)
      await this.api.salaryApi.deleteSalary(this.changeStateParams).then((res) => {
        res = res.data
        console.log("----删除-----",res);
        if(res.code == 200){
          this.$message({
            message: '删除成功！',
            type: 'success'
          });
            this.auditMsgs.dialogVisible = false;
            this.loadTable();
        }else{
            this.$message.error(res.msg);
          }
      })
    },
    toFloat(val){
			if(this.isEntity(val)){
				return '';
			}
			return parseFloat(val)/10000+"%";
		},
		// isEntity(val){
		// 	if(val == null || val == undefined || val === ''){
		// 		return true;
		// 	}else{
		// 		return false;
		// 	}
		// },

      async deleteSalarys(row){
        this.auditMsgs.dialogVisible = true;
        this.auditMsgs.msg='确认删除吗？'
        this.auditMsgs.msgClass='submitMsgs'
        this.auditMsgs.buttonClass='submitButtons'
        this.changeStateParams.id=row.id;
      },

      // 查看函数
      noDetails(row){
        this.$router.push({path: '/summaryApprovalDetails',query: {id: row.id,summaryNo:row.summaryNo,updateTime:row.updateTime}})

      },
      // 作废
      cancellation(){
        
      },

      // 点击上传汇总表
      openUploadFrom(title,id){
        this.uploadJson.summaryID = id;
        this.updateForm.groupIds=[];
        this.updateForm.type = 0;
        this.updateForm.auth = 3;
        this.updateForm.creditLimitApprovalId='';
        this.title = title;
        if(this.title === '上传发薪模版:'){
          this.upload = process.env.VUE_APP_BASE_URL+'/salarySummarizing/importSalarys'
        }else{
          this.upload = process.env.VUE_APP_BASE_URL+'/salarySummarizing/importPayrollDeclaration'
        }
        this.dialogFormVisible = true;
      },
      // 点击提交审核 changeState
      async submitAudit(row,status){
        
        this.auditMsg.dialogVisibles = true;
        this.auditMsg.msg='确认提交审核该汇总表吗？'
        this.auditMsg.msgClass='submitMsg'
        this.auditMsg.buttonClass='submitButton'
        this.auditMsg.status=0
        this.changeStateParams.id=row.id;
        this.changeStateParams.status=status;
        this.changeStateParams.errorNum = row.errorCount
        // this.changeState(params);
      },

      async submitAudits(row,status){
        this.auditMsg.dialogVisibles = true;
        this.auditMsg.msg='确认要重新提交审核该汇总表吗？'
        this.auditMsg.msgClass='submitMsg'
        this.auditMsg.buttonClass='submitButton'
        this.auditMsg.status=0
        this.changeStateParams.id=row.id;
        this.changeStateParams.status=status;
        // this.changeState(params);
      },
      // 点击撤销审核
      async repealAudit(row,status){
        if(status==1){
          this.auditMsg.dialogVisible = true;
          this.auditMsg.msg='确认撤销审核该汇总表吗？'
          this.auditMsg.msgClass='repealMsg'
          this.auditMsg.buttonClass='repealButton'
          this.auditMsg.status=1
          this.changeStateParams.id=row.id;
          this.changeStateParams.status=status;
        }else if(status==5){

          this.auditMsg.dialogVisible = true;
          this.auditMsg.msg='确认要作废表单吗？'
          this.auditMsg.msgClass='cancellationMsg'
          this.auditMsg.buttonClass='cancellationButton'
          this.auditMsg.status=1
          this.changeStateParams.id=row.id;
          this.changeStateParams.status=status;

        }
        
        // this.changeState(params);
      },
      // parseXlsxPageChange
      parseXlsxPageChange(page){
        console.log(page+'page');
        this.parsePage.pageNo = page;
      },
      //parseXlsxPagesizeChange 
      parseXlsxPagesizeChange(size){
        console.log(size+'size');
        this.parsePage.pageSize = size;
      },
      // 分页器 size改变是触发
       //current-change
      currentChange(page){
        this.queryForm.pageNo = page;
        this.loadTable();
      },

      // size-change
      sizeChange(size){
        this.queryForm.pageSize = size;
        this.loadTable();
      },
      // 改变状态
     async changeState(){
       const loading = this.$loading({
          lock: true,
          text: '数据处理中，请稍等',
        });
        this.changeStateParams.sessionId=this.$getCookie("sessionId");
        let flag = this;
        this.fullscreenLoading = true;
        // if(this.changeStateParams.errorNum > 0){
        //   flag.$message.error('明细表有异常数据，请修改后再操作！');
        //   flag.auditMsg.dialogVisible=false;
        //   this.fullscreenLoading = false;
        //   loading.close();
        //   return;
          
        // }
        if(this.changeStateParams.status == 0){
          // 提交审核
          console.log("--------one",this.changeStateParams.status)
          await this.api.salaryApi.updateSubmitReviewStatus(this.changeStateParams).then(res =>{
            console.log("------res1",res)

          if(res.data.code==200){
            flag.loadTable();
            flag.$message({
              message: '操作成功！',
              type: 'success'
            });
            
          }else{
           flag.$message.error('操作失败！');
          }
          flag.auditMsg.dialogVisibles=false;
          this.fullscreenLoading = false;
          loading.close();
        })
        //撤销审核
        console.log("--------2",this.changeStateParams)
        } else if(this.changeStateParams.status == 1){
            await this.api.salaryApi.updateRevokeApprovalStatus(this.changeStateParams).then(res =>{
              console.log("------res2",res)
              if(res.data.code==200){
                flag.loadTable();
                flag.$message({
                  message: '操作成功！',
                  type: 'success'
                });
              }else{
                flag.$message.error('操作失败！');
              }
                flag.auditMsg.dialogVisibles=false;
              })
              this.fullscreenLoading = false;
              loading.close();
        }
          else{
          // 这里是作废的
          console.log("--------3",this.changeStateParams)
          await this.api.salaryApi.updateNullifyStatus(this.changeStateParams).then(res =>{
            console.log("------res3",res.data)
            if(res.data.code==200){
              flag.loadTable();
              flag.$message({
                message: '操作成功！',
                type: 'success'
              });
            }else{
              flag.$message.error('操作失败！');
            }
              flag.auditMsg.dialogVisible=false;
            })
            this.fullscreenLoading = false;
            loading.close();
        }
        
      },
      // // 条件查询
      // onQuery() {
      //   let groupIds = this.queryForm.groupIds;
      //   if(groupIds!= undefined && groupIds.length>0){
      //     this.queryForm.groupId = groupIds[groupIds.length-1]
      //   }
      //   this.loadTable();
      // },
      // 加载查看列表函数
      // async loadTable(){
      //   // tableData
      //   // this.tableLoading = true;
      //   let flag = this;
      //   await this.api.platformApi.listImportSalary(this.queryForm).then(res => {
      //     res = res.data
      //     this.tableLoading = false;
      //     let data = res.data;
      //     let list = data.records;
      //     for (let i in list) {
      //       let state = flag.summaryArray[list[i].state];
      //       let name = '';
      //       if(!this.isEmpty(state)){
      //         name = state.name;
      //       }
      //       list[i]['stateName'] = name
      //     }
      //     flag.tableData = list;
      //     flag.total=data.total;
          
      //   })
      // },
      // 上传表单
      submitUploadFrom(){
        console.log('开始上传');
        this.uploadJson.sessionId = this.$getCookie("sessionId");
        this.$refs.upload.submit();
        console.log('上传成功！');
        
      },
      downloadSalary(){
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = process.env.VUE_APP_BASE_URL+process.env.VUE_APP_PAYROLL_TEMPLATE;
        link.setAttribute('download', decodeURIComponent(process.env.VUE_APP_PAYROLL_TEMPLATE))
        document.body.appendChild(link)
        link.click();
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
        console.log('下载成功！')
      },
      // afteraddOrUpdateDialogClose 清空弹窗表单
      afteraddOrUpdateDialogClose(){
          this.resetForm('updateForm');
          this.$refs.upload.$data.uploadFiles = [];
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      // 下载
      onDownload(){
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = process.env.VUE_APP_BASE_URL+process.env.VUE_APP_TEMPLATE_SUMMARY
        link.setAttribute('download', decodeURIComponent(process.env.VUE_APP_TEMPLATE_SUMMARY))

        document.body.appendChild(link)
        link.click();
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
        console.log('下载成功！')
      },
      // 重置
      onReset(){
        this.queryForm.summaryNo='';
        this.queryForm.groupId='';
        this.queryForm.status='';

      },
      // 上传表格
      submitUpload() {
        console.log('submitUpload!');
        this.$refs.upload.submit();
      },
      // 删除一选择的上传文件函数
      handleRemove(file, fileList) {
        console.log('handleRemove!');
        this.updateForm.isFile = null;
        console.log(file, fileList);
      },
      // 点击文件列表中已上传的文件时的钩子
      handlePreview(file) {
        console.log('handlePreview!');
        console.log(file);
      },
      // 上传失败的钩子
      handleError(err, file, fileList){
        this.$message.error('上传失败！');
      },
      // 上传成功的钩子
      handlesuccess(response, file, fileList){
        this.updateForm.groupId = [];
        this.fileList = []
        this.dialogFormVisible = false;
        this.fullscreenLoading = true;
        console.log("上传回传",response)
        if(response.code == 200 && (response.data == null || response.data == false)){
          setTimeout(() => {
            this.fullscreenLoading = false;
            this.$message({
            message: '上传成功！',
            type: 'success'
          });
            this.loadTable();
          }, 3000);
          // console.log('handlesuccess',response);
        }else{
          setTimeout(() => {
            this.fullscreenLoading = false;
            this.$message.error(response.msg);
            this.loadTable();
          }, 3000);
        }
        
      },
      // formatDate(numb, format) {
      //   const old = numb - 1;
      //   const t = Math.round((old - Math.floor(old)) * 24 * 60 * 60);
      //   const time = new Date(1900, 0, old, 0, 0, t)
      //   const year = time.getFullYear() ;
      //   const month = time.getMonth() + 1 ;
      //   const date = time.getDate() ;
      //   return year + format + (month < 10 ? '0' + month : month) + format + (date < 10 ? '0' + date : date)
      // },
      // // 验证时间是否合法
      // checkDate(dateStr){
      //   var a = /^((((1[6-9]|[2-9]\d)\d{2})\/(0?[13578]|1[02])\/(0?[1-9]|[12]\d|3[01]))|(((1[6-9]|[2-9]\d)\d{2})\/(0?[13456789]|1[012])\/(0?[1-9]|[12]\d|30))|(((1[6-9]|[2-9]\d)\d{2})\/0?2\/(0?[1-9]|1\d|2[0-8]))|(((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))\/0?2\/29\/))$/;
      //   if (a.test(dateStr)) {
      //       return true;
      //   }else{
      //       return false;
      //   }
      // },
      // // 非空验证
      // isEmpty(strIn) {
      //     if (strIn === undefined) {
      //         return true;
      //     } else if (strIn == null) {
      //         return true;
      //     } else if (strIn == "") {
      //         return true;
      //     } else {
      //         return false;
      //     }
      // },
      async findDictionariesItemList(param) {
				let response = await this.api.platformApi.findDictionariesItemList(param);
				console.log("数据字典", response);
				this.itemList = response.data.data;
			},

      tableHeader(column) {
        return "height:36px;background-color:#fafafa;color:#333333;padding:0";
      },
      tableCell(column) {
        return "height:40px;background-color:#fafafa;color:#000000;padding:0";
      },
    },
}
</script>

<style scoped>

  .submitMsgs{
    color: red;
    font-size: 1.5rem;
    
  }
  .submitButtons{
    background-color: red;
    border: none;
  }
  .submitMsg{
    color: #09BF88;
    font-size: 1.5rem;
    
  }
  .submitButton{
    background-color: #09BF88;
    border: none;
  }
  .submitButton:hover{
    background-color: #3ACCA0;
  }
  .repealMsg{
    color: #FFAC1D;
    font-size: 1.5rem;
  }
  .repealButton{
    background-color: #FFAC1D;
    border: none;
  }
  .repealButton:hover{
    background-color: #FFBD4A;
  }

  .cancellationMsg{
    color: #F78969;
    font-size: 1.5rem;
  }
  .cancellationButton{
    background-color: #F78969;
    border: none;
  }
  .cancellationButton:hover{
    background-color: #F789;
  }
/* -------------------------------------- */

#page {
  float: right;
  margin-top: 15px;
}

#quotaReviewList {
  overflow: hidden;
  background-color: white;
  padding: 15px 20px 100px;
}

.el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgb(228, 228, 228, 1);
  padding-left: 0;
  padding-right: 0;
}

.el-drawer.rtl {
  overflow: scroll;
}

.updateForm{
  width: 90%;
  margin: 0 auto;
}
.parseXlsxBottom{
  text-align: end;
  padding: 20px;
}
</style>


